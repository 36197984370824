.container {
    margin: 25px 15px 25px 15px;
    border: 4px solid #9a5ba961;
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 10px;
}

.itemList {
    width: 480px;
    height: 150px;
    overflow-y: hidden;
    list-style: none;
    margin-block-end: 1em;
    margin-block-start: 1em;
    padding: 10px 20px 10px 15px;
}

.itemList:hover {
    overflow-y: auto;
}

.itemList::-webkit-scrollbar {
    width: 7px;
    background: #c1726f;
}

.itemList::-webkit-scrollbar-thumb {
    background: #feb9b4;
}

@media screen and (max-width: 610px) {
    .container {
        margin: 10px;
    }

    .itemList {
        padding-inline-start: 0;
        padding: 0;
        width: auto;
    }
}