.checkboxInput[type="checkbox"] {
    display: none;
}

.checkboxEdit, .checkboxOk {
    cursor: pointer;

}

.checkboxEdit {
    content:"";
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('../../Images/Change.svg');
    vertical-align: middle;
    margin-left: 5px;
}

.checkboxEdit:hover {
    background: url('../../Images/ChangeHover.svg');
}

.checkboxOk {
    content:"";
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('../../Images/Okey.svg');
    vertical-align: middle;
    margin-left: 5px;
}



