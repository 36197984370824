.box {
    display: grid;
    justify-content: center;
    grid-template-rows: 50px auto 70px;
}

.form {
    font-size: 16px;
    position: relative;
    overflow: hidden;
}

.inputItem {
    width: 220px;
    height: 28px;
    padding-top: 20px;
    border: none;
    background: none;
    outline: none;
    color: #FCFF6B;
    text-transform: uppercase;
}

.labelName {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-bottom: 1px solid #ffff;
    color: #ffff;
}

.labelName::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 100%;
    border-bottom: 3px solid #FCFF6B;
    transform: translateX(-100%);
    transition: all 0.3s ease;
}

.contentName {
    position: absolute;
    bottom: 5px;
    left: 0;
    transition: all 0.3s ease;
}

.inputItem:focus + .labelName .contentName, 
.inputItem:valid + .labelName .contentName {
    transform: translateY(-150%);
    font-size: 14px;
    color: #FCFF6B;
}

.inputItem:focus + .labelName::after, 
.inputItem:valid + .labelName::after {
    transform: translateX(0);
}

.button {
    justify-self: center;
    position: relative;
    top: 14px;
    font-family: sans-serif;
    width: 137px;
    height: 38px;
    background: linear-gradient(180deg, #FCFF6B 0%, rgba(226, 145, 24, 0.79) 100%);
    border-radius: 5px;
    border-style: none;
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s linear;
    transform: translateY(0);
    outline: none;
}

.button:active {
    transition: transform 0.3s linear;
    transform: translateY(7px);
}

.errorInput {
    color: rgba(206, 243, 106, 0.993);
    font-size: 14px;
    margin:0;
}