.checkboxInput[type="checkbox"] {
    display: none;
}

.checkboxLabel {
    cursor: pointer;
}

.checkboxLabel {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('../../Images/ChekboxDone_A.svg');
    vertical-align: middle;
    transition: all .3s ease;
}

.checkboxInput[type="checkbox"]:checked + .checkboxLabel  {
    transition: all .3s ease;
    background: url('../../Images/ChekboxDone_C.svg');
}

