.checkboxInput[type="checkbox"] {
    display: none;
}

.checkboxLabel {
    cursor: pointer;
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('../../Images/ChekboxRemove.svg');
    vertical-align: middle;
}


